import { Box, styled, Tab, Tabs } from 'components';
import Typography from '@mui/material/Typography';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoutes } from 'utils';
import { RetentionContext } from 'components/Retention/utils';
import { MdArrowBack } from 'components/icons';
import MemberOverview from './MemberOverview';
import { Button, TabPanel } from '../../../../shared';
import { PathLevel } from './PathLevel';
import { ICareerPathGroupByOrganization } from './types';

const tabs = {
  PATH: {
    label: 'Path',
    key: 0,
  },
  MEMBERS: {
    label: 'Members',
    key: 1,
  },
};

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '1.5rem 0',
  '& .path-group-title-container': {
    paddingBottom: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .career-path-group-items-container': {
    paddingTop: 15,
    color: theme.palette.text.secondary,
  },
  '& .employees-assigned-container': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '2rem',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 0,
    },
  },
  '& .backButton': {
    marginBottom: 24,
  },
}));

const EditPathGroup = ({
  activePath,
}: {
  activePath: ICareerPathGroupByOrganization;
}) => {
  const [currentTab, setCurrentTab] = useState<number | string>(
    0,
  );
  const [openedPathGroupItems, setOpenPathGroupItems] = useState<
    number[]
  >(
    activePath.careerPathGroupItems.map(
      (cpgi) => cpgi.careerPathGroupItemId,
    ),
  );
  const ctx = useContext(RetentionContext);
  const navigate = useNavigate();
  const routes = getRoutes();

  return (
    <StyledBox>
      <Button
        onClick={() =>
          navigate(
            `${routes.employer.retention_portal.location}/${ctx.retentionPages.path}`,
          )
        }
        variant={'outlined-secondary'}
        className={'backButton'}
        startIcon={<MdArrowBack />}
      >
        Back
      </Button>
      <Box className={'path-group-title-container'}>
        <Typography variant={'h2'}>{activePath.name}</Typography>
        {/* <Button startIcon={<MdAdd />}>Assign Employee</Button> */}
      </Box>
      <Box>
        <Tabs
          value={currentTab}
          onChange={(
            e: React.SyntheticEvent,
            newValue: number,
          ) => setCurrentTab(newValue)}
        >
          <Tab
            label={tabs.PATH.label}
            tabIndex={tabs.PATH.key}
          />
          <Tab
            label={tabs.MEMBERS.label}
            tabIndex={tabs.MEMBERS.key}
          />
        </Tabs>
      </Box>
      <TabPanel
        selected={currentTab === tabs.PATH.key}
        value={tabs.PATH.label}
      >
        <Box className={'career-path-group-items-container'}>
          <Typography variant={'h3'}>
            {tabs.PATH.label}
          </Typography>
          {activePath.careerPathGroupItems.map(
            (careerPathGroupItem) => (
              <PathLevel
                key={
                  careerPathGroupItem.careerPathGroupItemId ||
                  careerPathGroupItem.jobTypeId
                }
                handleOpen={(id: number) =>
                  setOpenPathGroupItems(
                    openedPathGroupItems.includes(id)
                      ? openedPathGroupItems.filter(
                          (i) => i !== id,
                        )
                      : [...openedPathGroupItems, id],
                  )
                }
                pathGroupItem={careerPathGroupItem}
                // count={index + 1}
                isActive={openedPathGroupItems.includes(
                  careerPathGroupItem.careerPathGroupItemId ||
                    careerPathGroupItem.jobTypeId,
                )}
              />
            ),
          )}
        </Box>
      </TabPanel>
      <TabPanel
        selected={currentTab === tabs.MEMBERS.key}
        value={tabs.MEMBERS.label}
      >
        <Box className={'employees-assigned-container'}>
          <MemberOverview activePath={activePath} />
        </Box>
      </TabPanel>
    </StyledBox>
  );
};

export default EditPathGroup;

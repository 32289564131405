import React, { useMemo, useState } from 'react';
import {
  betaScheduleTesters,
  CONTACT_EMAIL,
  EMPLOYER_TUTORIAL_URL,
  enableScheduleAssistant,
  getRoutes,
  getUserId,
  SUPPORT_EMAIL,
} from 'utils';
import {
  useEmployerProfileQuery,
  useMediaQueryMatches,
  useWindowSize,
} from 'hooks';
import { Box, Dialog, styled } from 'components';
import { Button, IconButton, Spinner } from 'components/shared';
import {
  MdCalendarMonth,
  MdChat,
  MdClose,
  MdHelp,
  MdPeople,
  MdPlayArrow,
  MdSearch,
  MdTimeline,
  MdWork,
} from 'components/icons';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import EmbedVideo from 'components/shared/Video/EmbedVideo';
import styles from 'styles/Dashboard/EnterpriseDashboard';
import defaultEmployerImg from 'assets/img/employer_default.png';

const StyledRoot = styled('div')(styles);

const ROUTES = getRoutes();

const SEARCH_DB_BUTTON = {
  title: 'Search Candidate Database',
  id: 'search-database-link',
  to: ROUTES.employer.enterprise_search,
  Icon: MdSearch,
};
const JOBS_BUTTON = {
  title: 'Jobs',
  id: 'employer-jobs-link',
  to: ROUTES.employer.jobs,
  Icon: MdWork,
};
const TEAM_MANAGEMENT_BUTTON = {
  title: 'Team Management',
  id: 'wt-grow-link',
  Icon: MdPeople,
};
const HELP_BUTTON = {
  title: 'Get Help With Your Account',
  id: 'help-link',
  Icon: MdHelp,
};
const CONTACT_BUTTON = {
  title: 'Contact Us',
  id: 'contact-us-link',
  Icon: MdChat,
};
const INTERVIEW_SCHEDULE_BUTTON = {
  title: 'Set Up Interview Scheduling',
  id: 'schedule-link',
  to: ROUTES.employer.enterprise_schedule,
  Icon: MdCalendarMonth,
};
const RETENTION_PORTAL_BUTTON = {
  title: 'Retention Portal',
  id: 'team-management-link',
  to: ROUTES.employer.retention_portal.root,
  Icon: MdTimeline,
};

const ONBOARDING_BUTTON = {
  title: 'Onboarding',
  id: 'onboarding-link',
  to: ROUTES.employer.onboarding.root,
  Icon: HourglassEmptyIcon,
};

const SCHEDULING_BUTTON = {
  title: 'Workforce Scheduling',
  id: 'workforce-scheduling-link',
  to: ROUTES.employer.onboarding.root,
  Icon: PunchClockIcon,
  disabled: true,
};

function calcMobileCardSize(screenWidth = 0) {
  const w = screenWidth / 3;
  return {
    // width: w,
    height: (w / 4) * 3,
  };
}

const EnterpriseDashboard = () => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { width: screenWidth } = useWindowSize();
  const userId = useMemo(() => Number(getUserId()), []);

  const {
    profile,
    canAllowEnterprise,
    loading: profileLoading,
    subscriptionInfo,
  } = useEmployerProfileQuery();
  const { imageUrl, name } = profile || {};
  const { planName } = subscriptionInfo || {};

  const [isComingSoonOpen, setIsComingSoonOpen] =
    useState(false);

  const showScheduleLink =
    enableScheduleAssistant ||
    betaScheduleTesters.indexOf(userId) > -1;

  const size = isDesktop ? {} : calcMobileCardSize(screenWidth);

  const toggleComingSoon = (open) => () =>
    setIsComingSoonOpen(open);

  const createButton = ({
    title,
    id,
    Icon,
    to,
    disabled,
    ...rest
  }) => (
    <div className="card">
      <Button
        disabled={disabled}
        isRouterLink={!!to}
        to={to}
        className="cardBtn"
        style={size}
        testID={id}
        {...rest}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {Icon ? (
            <div className="cardBtn__icon">
              <Icon color="inherit" fontSize="inherit" />
            </div>
          ) : null}
          <div className="cardBtn__title">{title}</div>
        </Box>
      </Button>
    </div>
  );

  const renderVideoTutorial = () =>
    !isDesktop ? (
      <div className="card">
        <EmbedVideo
          src={EMPLOYER_TUTORIAL_URL}
          triggerBtnProps={{ className: 'cardBtn', style: size }}
          triggerBtnContent={
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <div className="cardBtn__icon">
                <MdPlayArrow
                  color="inherit"
                  fontSize="inherit"
                />
              </div>
              <div
                className="cardBtn__title"
                style={{ maxWidth: 211 }}
              >
                Watch Tutorial
              </div>
            </Box>
          }
        />
      </div>
    ) : (
      <div
        className="cardBtn sideCard tutorialCardBtn"
        style={{ height: 'auto' }}
      >
        <EmbedVideo
          src={EMPLOYER_TUTORIAL_URL}
          iframeProps={{ height: 174 }}
        />
      </div>
    );

  const renderCompanyCard = () => (
    <div className="cardBtn sideCard employerPlan">
      {profileLoading ? (
        <div className="profileLoaderWrapper">
          <Spinner size={60} />
        </div>
      ) : (
        <Box display="flex">
          <img
            src={imageUrl || defaultEmployerImg}
            alt=""
            className="companyLogo"
          />
          <Box width="100%">
            <div className="companyName">{name}</div>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="flex-start"
              justifyContent="space-between"
              fontSize={14}
              lineHeight="17px"
            >
              {planName && (
                <Box
                  component="span"
                  mr={planName ? '16px' : 0}
                >{`Plan: ${planName}`}</Box>
              )}{' '}
              <Button
                isRouterLink
                to={ROUTES.employer.profile}
                variant="text"
                sx={{
                  fontSize: 'inherit',
                  lineHeight: 'inherit',
                  verticalAlign: 'baseline',
                }}
                testID="enterprise-dashboard-edit-profile-button"
              >
                Edit Profile
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );

  const renderComingSoonDialog = () => (
    <Dialog
      open={isComingSoonOpen}
      fullWidth
      onClose={toggleComingSoon(false)}
    >
      <Box
        py="5px"
        px="15px"
        display="flex"
        justifyContent="flex-end"
      >
        <IconButton
          color="primary"
          onClick={toggleComingSoon(false)}
        >
          <MdClose />
        </IconButton>
      </Box>
      <Box pb="40px" px="15px">
        <Box textAlign="center" fontSize={16} fontWeight="bold">
          Coming Soon!
        </Box>
      </Box>
    </Dialog>
  );

  const qhGrowProps = process.env
    .ALLOW_CAREER_DEVELOPMENT_EMPLOYER
    ? {
        isRouterLink: true,
        to: ROUTES.employer.enterprise_grow,
      }
    : {
        onClick: toggleComingSoon(true),
      };

  function renderDesktopLayout() {
    return (
      <div className="container">
        <div className="mainTemplateContainer">
          {createButton({
            ...SEARCH_DB_BUTTON,
            disabled: !canAllowEnterprise,
          })}
          {createButton(JOBS_BUTTON)}
          {createButton({
            ...TEAM_MANAGEMENT_BUTTON,
            ...qhGrowProps,
          })}
          {createButton({
            ...HELP_BUTTON,
            component: 'a',
            href: `mailto:${SUPPORT_EMAIL}`,
          })}
          {showScheduleLink &&
            createButton(INTERVIEW_SCHEDULE_BUTTON)}
          {createButton(RETENTION_PORTAL_BUTTON)}
          {createButton(ONBOARDING_BUTTON)}
          {createButton(SCHEDULING_BUTTON)}
          {!isDesktop && (
            <div className="card">
              <EmbedVideo
                src={EMPLOYER_TUTORIAL_URL}
                triggerBtnProps={{ className: 'cardBtn' }}
                triggerBtnContent={
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <div className="cardBtn__icon">
                      <MdPlayArrow
                        color="inherit"
                        fontSize="inherit"
                      />
                    </div>
                    <div
                      className="cardBtn__title"
                      style={{ maxWidth: 211 }}
                    >
                      Watch Tutorial
                    </div>
                  </Box>
                }
              />
            </div>
          )}
        </div>

        <div className="sideTemplateContainer">
          {renderCompanyCard()}
          <Button
            component="a"
            href={`mailto:${CONTACT_EMAIL}`}
            className="cardBtn sideCard"
            testID="contact-us-link"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <div className="cardBtn__icon">
                <MdChat color="inherit" fontSize="inherit" />
              </div>
              <div className="cardBtn__title">Contact Us</div>
            </Box>
          </Button>
          {renderVideoTutorial()}
        </div>
      </div>
    );
  }

  const renderMobileLayout = () => (
    <div className="container">
      <div className="mainTemplateContainer">
        {createButton({
          ...SEARCH_DB_BUTTON,
          disabled: !canAllowEnterprise,
        })}
        {createButton(JOBS_BUTTON)}
        {createButton({
          ...TEAM_MANAGEMENT_BUTTON,
          ...qhGrowProps,
        })}
        {showScheduleLink &&
          createButton(INTERVIEW_SCHEDULE_BUTTON)}
        {createButton(RETENTION_PORTAL_BUTTON)}
        {createButton(ONBOARDING_BUTTON)}
        {createButton({ ...SCHEDULING_BUTTON, disabled: true })}
        {createButton({
          ...CONTACT_BUTTON,
          component: 'a',
          href: `mailto:${CONTACT_EMAIL}`,
        })}
        {createButton({
          ...HELP_BUTTON,
          component: 'a',
          href: `mailto:${SUPPORT_EMAIL}`,
        })}
        {renderVideoTutorial()}
      </div>
    </div>
  );

  return (
    <StyledRoot className="enterpriseDashboardContainer">
      {isDesktop ? renderDesktopLayout() : renderMobileLayout()}
      {renderComingSoonDialog()}
    </StyledRoot>
  );
};

export default EnterpriseDashboard;

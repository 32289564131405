export const baseGap = 30;
export const locationDetailHeight = 210;
export const navHeight = 70;
export const dashboardImageWidth = 25;
export const backgroundBorderMixin = ({ theme }) => ({
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: 15,
});
export const backgroundTransparentBorderMixin = ({ theme }) => ({
  background: 'transparent',
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: 15,
});

export const containerPaddingMixin = (multiplier = 1) => ({
  padding: `${baseGap * multiplier}px`,
});

export const shadowMixin = ({ depth }) => {
  const depthBase = 0.1;
  const depthBasePx = 1;
  const alpha = depthBase * depth * 0.5;
  return {
    boxShadow: `0 0 ${
      depthBasePx * depth * 5
    }px rgba(0, 0, 0, ${alpha})`,
  };
};
export const retentionNavContainer = ({ theme }) => ({
  [theme.breakpoints.up('desktopApp')]: {
    display: 'grid',
    gridTemplateColumns: '75px auto',
    columnGap: baseGap,
    // padding: baseGap,
    minHeight: `calc(100vh - ${navHeight + baseGap}px)`,
    width: '100%',
    '& .retention-navigation': {
      display: 'flex',
      height: `calc(100vh - ${navHeight + baseGap * 2}px)`,
      justifyContent: 'center',
      ...backgroundBorderMixin({ theme }),
      position: 'sticky',
      top: 65,
    },
    '& .nav': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      button: {
        margin: '12.5px 0px',
      },
    },
    '& .worktorchLogo': {
      margin: '12.5px auto',
      paddingBottom: 12.5,
      borderBottom: `1px solid ${theme.palette.primary.light}`,
      width: '80%',
      svg: {
        height: 32,
        width: '100%',
        display: 'block',
      },
    },
  },
  [theme.breakpoints.down('desktopApp')]: {
    '& .worktorchLogo': {
      display: 'none',
    },
  },
  '.nav': {
    [theme.breakpoints.up('desktopApp')]: {
      '.desktopNavigation': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      },
    },
    [theme.breakpoints.down('desktopApp')]: {
      position: 'fixed',
      // 84 pixels to take into account the chat button
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
});

import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import {
  employeeStartPage,
  employerStartPage,
  getRoutes,
  saveRequiredUserValues,
} from 'utils';
import { Spinner } from 'components/shared';
import {
  useAuth,
  useEmployeeProfileQuery,
  useEmployerProfileQuery,
} from 'hooks';
import { OnboardingPageLayout } from 'containers';

const ROUTES = getRoutes();

const ProtectedOnboardingRoute = () => {
  const { authed } = useAuth();
  const navigate = useNavigate();
  const { fetch: fetchEmployerProfile } =
    useEmployerProfileQuery({ autoFetch: false });
  const { fetch: fetchEmployeeProfile } =
    useEmployeeProfileQuery({ autoFetch: false });

  const [isUserRoleChecked, setIsUserRoleChecked] =
    useState(false);

  const checkProfileByRole = async () => {
    const { data: employerData } = await fetchEmployerProfile();

    if (employerData?.employerProfile) {
      const { profileWizard, regStep } =
        employerData.employerProfile;

      if (profileWizard) {
        saveRequiredUserValues(employerData.employeeProfile);
        navigate(employerStartPage);
        return false;
      }

      if (employerData.employerProfile.profile_id) {
        saveRequiredUserValues(employerData.employerProfile);
      }

      if (regStep) {
        navigate(ROUTES.onboarding.default, {
          replace: true,
          state: { regStep: `employer_${regStep}` },
        });
      }
      return true;
    }

    const { data: employeeData } = await fetchEmployeeProfile();

    if (employeeData?.employeeProfile) {
      const { profileWizard, regStep } =
        employeeData.employeeProfile;

      if (profileWizard) {
        saveRequiredUserValues(employeeData.employeeProfile);
        navigate(employeeStartPage);
        return false;
      }

      if (employeeData.employeeProfile.profile_id) {
        saveRequiredUserValues(employeeData.employeeProfile);
      }

      if (regStep) {
        navigate(ROUTES.onboarding.default, {
          replace: true,
          state: { regStep: `employee_${regStep}` },
        });
      }
      return true;
    }

    return true;
  };

  const authMiddleware = async () => {
    if (authed) {
      const checked = await checkProfileByRole();
      setIsUserRoleChecked(checked);
    }
  };

  useEffect(() => {
    authMiddleware();
  }, []);

  const renderContent = () =>
    isUserRoleChecked ? (
      <OnboardingPageLayout>
        <Outlet />
      </OnboardingPageLayout>
    ) : (
      <div
        style={{
          position: 'fixed',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner size={30} />
      </div>
    );

  return authed ? (
    renderContent()
  ) : (
    <Navigate to={ROUTES.login.default} />
  );
};

export default ProtectedOnboardingRoute;
